import Vue from "vue";
import Vuex from "vuex"


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        projects: []
    },
    getters: {
        getProject(state) {
            return state.projects

        }
    },
    mutations: {
        createProject(state, project) {
            state.projects.push(project)
        }
    },
    actions: {
        triggerCreateProject(context, project) {
            context.commit('createProject', project)
        }

    },
    modules: {}
})