<template>
  <div>
<Header/>

  <router-view></router-view>

 
  </div>
</template>

<script>

import Header from "./components/shared/Header.vue"

export default {
  components:{
    Header,
    
  }
}
</script> 
<style >
*{
  margin: 0;
  padding: 0;
  font-family:Arial, Helvetica, sans-serif
}
body{
  margin: 0;
  padding: 0;
  height: 100vh;
}
</style>