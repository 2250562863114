<template>
  <nav>
    <input type="checkbox" id="check" />
    <label for="check">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="currentColor"
        class="bi bi-list"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    </label>
    <router-link to="/" tag="label" class="logo" style="cursor:pointer"
      ><img src="https://www.sempeak.com/yuklemeler/imaj/logo-copy.svg" width="110"
    /></router-link>
    <ul>
      <router-link tag="li" to=""><a href="#">DASHBOARD</a></router-link>
      <router-link tag="li" to="/projects" ><a href="#">PROJECTS</a></router-link>
      <router-link tag="li" to="/users"><a href="#">USERS</a></router-link>
      <router-link tag="li" to="/departments"><a href="#">DEPARTMENTS</a></router-link>
      <router-link tag="li" to=""><a href="#">REPORTING</a></router-link>
      <router-link tag="li" to=""><a href="#">ACCOUNT SETTINGS</a></router-link>
      <router-link tag="li" to="/login"><a href="#">LOGIN</a></router-link>
    </ul>
  </nav>
</template>


<script>
export default {};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
.logo>img{
   
    margin-top:14px;
    margin-left: 3%;
}
svg{
    color: white;
    float: right;
    line-height: 50;
    margin-right: 40px;
    margin-top: 10px;
    cursor: pointer;
    display: none;
}
nav {
  background-color: #474959;
  height: 7vh;
  width: 100%;
}
nav ul {
  float: right; 
  margin-right: 20px;
}
nav ul li {
  display: inline-block;
  line-height: 50px;
  margin: 0 10px;
}

nav ul li a {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
#check{
    display: none;
}

@media (max-width: 750px)  {
    label.img{
        font-size: 30px;
        padding-left: 50px;
    }
    svg{
        display: block;
    }
   nav ul{
        position: fixed;
        width: 80%;
        height: 100vh;
        background-color: #474959;
        z-index: 3;
        left: -100%;
        text-align: left;
        transition: all .5s;      
        opacity: 0; 
    }
    nav ul li{
        display: block;
        margin-left: 10%;
    }
   #check:checked ~ ul{
       left: 0;
       top: 50px;
      opacity: 1;
   }
}
</style>