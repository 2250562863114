import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../components/shared/home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../components/AUTH/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import ('../components/AUTH/Register.vue')
    },
    {
        path: '/projects',
        name: 'projects',
        component: () =>
            import ('../components/pages/project/projects.vue')
    },
    {
        path: '/addproject',
        name: 'add-project',
        component: () =>
            import ('../components/pages/project/addProject.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () =>
            import ('../components/pages/users/users.vue')
    },
    {
        path: '/adduser',
        name: 'add-user',
        component: () =>
            import ('../components/pages/users/addUser.vue')
    },
    {
        path: '/departments',
        name: 'departments',
        component: () =>
            import ('../components/pages/department/departments.vue'),

    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () =>
            import ('../components/pages/pricing/pricing.vue')
    },
    {
        path: '/updatepassword',
        name: 'update-password',
        component: () =>
            import ('../components/AUTH/updatePassword.vue')
    },
    {
        path: '/forgotpassword',
        name: 'forgot-password',
        component: () =>
            import ('../components/AUTH/forgotPassword.vue')
    },
    {
        path: '/verifycode',
        name: 'verify-code',
        component: () =>
            import ('../components/AUTH/verifyCode.vue')
    },
    {
        path: '/resetpassword',
        name: 'reset-password',
        component: () =>
            import ('../components/AUTH/resetPassword.vue')
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router